import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { intlShape, injectIntl } from 'react-intl';
import { Modal, ModalButtons, ModalContent, Button, TextField } from 'mw-style-react';
import { MODIFY_WORKSPACE } from 'constants';
import AppUtils from '../../../../utils/utils';
import TextButton from '../../../TextButton';
import mes from './intl';
import sModal from '../../Modal.scss'; // eslint-disable-line no-unused-vars

class ModifyWorkspace extends PureComponent {
  constructor(props) {
    super(props);
    const { name } = props.data;
    this.state = {
      isSubmit: false,
      name,
      error: false
    };
  }

  // Ф-ция локализации
  i(mesId, values) {
    return AppUtils.getMes(this.context)(mesId, values);
  }

  handleSubmit() {
    const { dispatch, onClose, data } = this.props;
    const { name } = this.state;

    if (!name.length) {
      this.setState({
        error: true
      });
      return;
    }

    this.setState({
      isSubmit: true
    });

    dispatch({
      type: MODIFY_WORKSPACE.REQUEST,
      payload: {
        body: { name: this.state.name },
        params: {
          workspaceId: data.ext_id
        },
        callback: result => {
          if (result === 'error') {
            this.setState({
              isSubmit: false
            });
          } else {
            onClose();
          }
        }
      }
    });
  }

  handleOnChange(e) {
    const { id, value } = e;
    this.setState({
      [id]: value,
      error: false
    });
  }

  render() {
    const { visibility, onClose } = this.props;

    const { name, isSubmit, error } = this.state;

    return (
      <Modal visibility={visibility} onClose={onClose} styleName="sModal.modal__workspace">
        <div styleName="sModal.modal__header">
          <div styleName="sModal.modal__header__close">
            <TextButton title="Cancel" onClick={onClose} />
          </div>
          <div styleName="sModal.modal__header__title">{this.i(mes.modifyWorkspace)}</div>
        </div>
        <div styleName="sModal.modal__body">
          <ModalContent styleName="sModal.modal__content">
            <div>
              <div styleName="sModal.modal__input__label">{this.i(mes.nameLabel)}</div>
              <TextField
                styleName="sModal.modal__input"
                value={name}
                onChange={this.handleOnChange.bind(this)}
                length={255}
                autoFocus
                bordered
                error={error}
                id="name"
                visibility={isSubmit ? 'disabled' : 'visible'}
              />
            </div>
          </ModalContent>
          <ModalButtons styleName="sModal.modal__buttons">
            <Button
              label={this.i(mes.btnModify)}
              size="medium"
              onClick={this.handleSubmit.bind(this)}
              visibility={isSubmit ? 'disabled' : 'visible'}
              styleName="sModal.modal__btn sModal.wide"
            />
          </ModalButtons>
        </div>
      </Modal>
    );
  }
}

ModifyWorkspace.propTypes = {
  visibility: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  data: PropTypes.object,
  dispatch: PropTypes.func
};

ModifyWorkspace.contextTypes = {
  intl: intlShape
};

export default injectIntl(ModifyWorkspace);
