import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  blockUser: {
    id: 'blockUser',
    defaultMessage: 'Block User'
  },
  btnBlockUser: {
    id: 'btnBlockUser',
    defaultMessage: 'Block'
  }
});

export default { ...globalIntl, ...m };
