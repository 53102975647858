/* eslint-disable no-confusing-arrow */
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { intlShape, injectIntl } from 'react-intl';
import { Modal, ModalButtons, Button, TextField } from 'mw-style-react';
import connect from 'react-redux/es/connect/connect';
import { MANAGE_USER_GROUPS, GET_GROUPS, GET_USER_GROUPS } from 'constants';
import AppUtils from '../../../../utils/utils';
import TextButton from '../../../TextButton';
import IconButton from '../../../IconButton';
import mes from './intl';
import GroupIcon from '../../../../images/group-icon.svg';

import sModal from '../../Modal.scss'; // eslint-disable-line no-unused-vars

class ManageUserGroups extends PureComponent {
  constructor(props) {
    super(props);
    const { data, dispatch } = props;
    this.state = {
      isSubmit: false,
      searchName: '',
      groupList: [],
      loading: true,
      selectedGroups: [],
      initialGroups: []
    };

    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);

    dispatch({
      type: GET_GROUPS.REQUEST,
      payload: {
        params: {
          workspaceId: data.workspaceId
        }
      }
    });

    dispatch({
      type: GET_USER_GROUPS.REQUEST,
      payload: {
        params: {
          workspaceId: data.workspaceId,
          userId: data.id
        },
        callback: (result, data) => {
          if (result === 'success' && data) {
            const transformed = data.map(d => ({ ...d, active: true }));
            this.setState({
              selectedGroups: transformed,
              initialGroups: transformed,
              loading: true
            });
          } else {
            this.setState({
              loading: false
            });
          }
        }
      }
    });
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  /**
   * Alert if clicked on outside of element
   */
  handleClickOutside(event) {
    const { focus } = this.state;
    if (focus && this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      this.setState({
        focus: false
      });
    }
  }

  // Ф-ция локализации
  i(mesId, values) {
    return AppUtils.getMes(this.context)(mesId, values);
  }

  handleSubmit() {
    const { dispatch, onClose, data } = this.props;
    const { workspaceId, id } = data || {};
    const { selectedGroups = [], initialGroups = [] } = this.state;
    const deleted = initialGroups.filter(iu => {
      const selected = selectedGroups.find(e => e.id === iu.id) || {};
      return !selected.active;
    });
    const added = selectedGroups.filter(iu => {
      if (!iu.active) return false;
      const selected = initialGroups.find(e => e.id === iu.id);
      if (!selected) return true;
      return false;
    });
    const groups = [
      ...deleted.map(u => ({ id: u.id, active: false })),
      ...added.map(u => ({ id: u.id, active: true }))
    ];

    this.setState({
      isSubmit: true
    });

    if (!groups.length) {
      onClose();
      return;
    }

    dispatch({
      type: MANAGE_USER_GROUPS.REQUEST,
      payload: {
        params: { workspaceId, userId: id, final: selectedGroups.filter(r => r.active) },
        body: groups,
        callback: result => {
          if (result === 'error') {
            this.setState({
              isSubmit: false
            });
          } else {
            setTimeout(() => {
              onClose();
            }, 100);
          }
        }
      }
    });
  }

  setFocus() {
    this.setState({
      focus: true
    });
  }

  addToActive(role) {
    this.setState(state => ({
      ...state,
      focus: false,
      searchName: '',
      selectedGroups: [...state.selectedGroups, role]
        .filter((value, index, self) => self.findIndex(v => v.id === value.id) === index)
        .map(u => (u.id !== role.id ? u : { ...u, active: true }))
    }));
  }

  handleRemoveItem(item) {
    const { id } = item || {};

    this.setState(state => ({
      ...state,
      selectedGroups: state.selectedGroups.map(u => (u.id !== id ? u : { ...u, active: false }))
    }));
  }

  handleOnChangeSearch(e) {
    const { value } = e;
    this.setState({
      searchName: value
    });
  }

  render() {
    const { visibility, onClose, data, groups } = this.props;

    const { list } = groups;

    const { searchName, isSubmit, selectedGroups, focus } = this.state;
    const filteredselectedGroups = selectedGroups.filter(s => s.active);

    const filteredgroupList = list
      ? list.filter(
          s => s.name.startsWith(searchName) && !filteredselectedGroups.find(g => g.id === s.id)
        )
      : [];

    return (
      <Modal
        visibility={visibility}
        onClose={onClose}
        styleName="sModal.modal__workspace sModal.modal__overflowVisible"
      >
        <div styleName="sModal.modal__header">
          <div styleName="sModal.modal__header__close">
            <TextButton title="Cancel" onClick={onClose} />
          </div>
          <div styleName="sModal.modal__header__title">{this.i(mes.manageUserGroupsHeader)}</div>
        </div>
        <div styleName="sModal.modal__body">
          <div styleName="sModal.modal__section">
            <div styleName="sModal.modal__description">
              Select groups to assign for <strong>{data.name}</strong>
            </div>

            <div style={{ marginTop: '20px', position: 'relative' }}>
              <div styleName="sModal.modal__input__label">Add group</div>
              <TextField
                styleName="sModal.modal__input"
                value={searchName}
                onChange={this.handleOnChangeSearch.bind(this)}
                placeholder="Search by name"
                leftIcon="search"
                length={255}
                bordered
                id="searchName"
                visibility={isSubmit ? 'disabled' : 'visible'}
                onFocus={this.setFocus.bind(this)}
              />
              {filteredgroupList.length && focus ? (
                <div styleName="sModal.modal__input__searchItems" ref={this.wrapperRef}>
                  <ul>
                    {filteredgroupList.map(u => (
                      <li key={u.id}>
                        <div
                          styleName="sModal.modal__input__searchItemControl"
                          role="button"
                          onClick={() => this.addToActive(u)}
                        >
                          <span>{u.name}</span>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              ) : null}
            </div>
            <div>
              {filteredselectedGroups.length ? (
                <div styleName="sModal.modal__list__items">
                  <ul>
                    {filteredselectedGroups.map(u => (
                      <li key={u.id}>
                        <div styleName="sModal.modal__list__items__element">
                          <div styleName="sModal.modal__list__items__element__group">
                            <span>{u.name}</span>
                          </div>
                          <IconButton icon="close" onClick={() => this.handleRemoveItem(u)} />
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              ) : (
                <div styleName="sModal.modal__list__empty">
                  <GroupIcon />
                  <span>{this.i(mes.manageUserGroupsEmptyList)}</span>
                </div>
              )}
            </div>
          </div>
          <ModalButtons styleName="sModal.modal__buttons">
            <Button
              label={this.i(mes.manageUserGroupsButton)}
              size="medium"
              onClick={this.handleSubmit.bind(this)}
              visibility={isSubmit ? 'disabled' : 'visible'}
              styleName="sModal.modal__btn sModal.wide"
            />
          </ModalButtons>
        </div>
      </Modal>
    );
  }
}

ManageUserGroups.propTypes = {
  visibility: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  data: PropTypes.object,
  groups: PropTypes.object,
  dispatch: PropTypes.func
};

ManageUserGroups.contextTypes = {
  intl: intlShape
};

const mapStateToProps = ({ groups }) => ({
  groups
});

export default injectIntl(connect(mapStateToProps)(ManageUserGroups));
