import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';
import { withRouter } from 'react-router-dom';
import Notify from '../../../components/Notify';
import Modal from '../../../components/Modal';
import '../../../styles/main.scss';

class MainLayout extends Component {
  // Отображение нотификации
  renderNotify() {
    const notify = this.props.notify || {};
    const items = notify.items || [];
    if (!items.length) return null;
    return <Notify {...this.props.notify} />;
  }

  // Отображение формы модального окна
  renderModal() {
    const { modal } = this.props;
    if (!modal) return null;
    return <Modal {...modal} />;
  }

  render() {
    const { children, theme, match } = this.props;
    const { url = '' } = match || {};
    const hideFooter =
      url.startsWith('/workspace') ||
      url.startsWith('/profile') ||
      url.startsWith('/license') ||
      url.startsWith('/choose');
    const year = new Date().getFullYear();

    return (
      <div className={`theme-${theme}`}>
        <div styleName="main">
          <div
            styleName={cn('main__wrapChildren', {
              hideFooter
            })}
          >
            {children}
          </div>
          {this.renderNotify()}
          {!hideFooter ? (
            <div styleName="main__footer">
              <div>{`${year} © Corezoid Inc. All rights reserved. Patent protected.`}</div>
            </div>
          ) : null}
        </div>
        {this.renderModal()}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  theme: state.theme,
  notify: state.notify,
  modal: state.modal
});

const mapDispatchToProps = dispatch => ({
  dispatch
});

MainLayout.propTypes = {
  theme: PropTypes.string,
  children: PropTypes.node.isRequired,
  modal: PropTypes.object,
  match: PropTypes.object,
  notify: PropTypes.object.isRequired
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MainLayout));
