import asyncRouter from './asyncRouter';
import MainLayout from '../containers/layouts/MainLayout/MainLayout';

const routes = [
  {
    path: '/',
    exact: true,
    layout: MainLayout,
    component: asyncRouter(() => import('./Main'))
  },
  {
    path: '/not_found',
    exact: true,
    layout: MainLayout,
    component: asyncRouter(() => import('./NotFound'))
  },
  {
    path: '/enter/:action/:hash?',
    exact: true,
    layout: MainLayout,
    component: asyncRouter(() => import('./Enter'))
  },
  {
    path: '/payment/:type?/:status?',
    exact: true,
    layout: MainLayout,
    component: asyncRouter(() => import('./Payment'))
  },
  {
    path: '/workspace/:workspaceId?/:category?/:itemId?/:tab?',
    exact: true,
    layout: MainLayout,
    component: asyncRouter(() => import('./Workspace'))
  },
  {
    path: '/choose/:status',
    exact: true,
    layout: MainLayout,
    component: asyncRouter(() => import('./Workspace'))
  },
  {
    path: '/:page/:action?/:id?/:tab?',
    exact: true,
    layout: MainLayout,
    component: asyncRouter(() => import('./Settings'))
  }
];

export default routes;
