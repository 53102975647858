/* eslint-disable react/jsx-indent */
/* eslint-disable max-len */
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { intlShape, injectIntl } from 'react-intl';
import { Modal, ModalButtons, ModalContent, Button } from 'mw-style-react';
import { DELETE_ROLE } from 'constants';
import cn from 'classnames';
import AppUtils from '../../../../utils/utils';
import TextButton from '../../../TextButton';
import history from '../../../../store/history';
import mes from './intl';
import sModal from '../../Modal.scss'; // eslint-disable-line no-unused-vars

class DeleteRole extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isSubmit: false
    };
  }

  // Ф-ция локализации
  i(mesId, values) {
    return AppUtils.getMes(this.context)(mesId, values);
  }

  handleSubmit() {
    const { dispatch, onClose, data, match } = this.props;
    const { params = {} } = match || {};

    this.setState({
      isSubmit: true
    });

    dispatch({
      type: DELETE_ROLE.REQUEST,
      payload: {
        params: {
          workspaceId: data.workspaceId,
          roleId: data.id
        },
        callback: result => {
          if (result === 'error') {
            this.setState({
              isSubmit: false
            });
          } else {
            if (params.itemId && params.category && params.workspaceId) {
              history().push(`/workspace/${params.workspaceId}/${params.category}`);
            }
            onClose();
          }
        }
      }
    });
  }

  render() {
    const { visibility, onClose } = this.props;

    const { isSubmit } = this.state;
    const {
      data: { name }
    } = this.props;
    return (
      <Modal visibility={visibility} onClose={onClose} styleName="sModal.modal__workspace">
        <div styleName="sModal.modal__header">
          <div styleName="sModal.modal__header__close">
            <TextButton title="Cancel" onClick={onClose} />
          </div>
          <div styleName="sModal.modal__header__title">{this.i(mes.titleDeleteRole)}</div>
        </div>
        <div styleName="sModal.modal__body">
          <ModalContent styleName="sModal.modal__content">
            <div>
              <div styleName="sModal.modal__description">
                Are you sure you want to delete the role <strong>{name}</strong>? If this role is
                the user&apos;s last role, then their role will be changed to &quot;member&quot;.
                This action is irreversible.
              </div>
            </div>
          </ModalContent>
          <ModalButtons styleName="sModal.modal__buttons">
            <Button
              label={this.i(mes.btnDeleteRole)}
              size="medium"
              onClick={this.handleSubmit.bind(this)}
              styleName={cn('sModal.modal__btn', 'sModal.red', 'sModal.wide', {
                red__disabled: isSubmit
              })}
              visibility={isSubmit ? 'disabled' : 'visible'}
            />
          </ModalButtons>
        </div>
      </Modal>
    );
  }
}

DeleteRole.propTypes = {
  visibility: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  data: PropTypes.object,
  dispatch: PropTypes.func,
  match: PropTypes.object
};

DeleteRole.contextTypes = {
  intl: intlShape
};

export default injectIntl(withRouter(DeleteRole));
