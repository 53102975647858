import React from 'react';

function asyncComponent(getComponent) {
  return class AsyncComponent extends React.Component {
    static Component = null;

    // eslint-disable-next-line react/state-in-constructor, no-use-before-define
    state = { Component: AsyncComponent.Component };

    // eslint-disable-next-line react/no-deprecated
    componentWillMount() {
      if (!this.state.Component) {
        getComponent().then(({ default: Component }) => {
          AsyncComponent.Component = Component;
          this.setState({ Component });
        });
      }
    }

    render() {
      const { Component } = this.state;
      if (Component) {
        return <Component {...this.props} />;
      }
      return null;
    }
  };
}

export default asyncComponent;
