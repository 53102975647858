import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { intlShape, injectIntl } from 'react-intl';
import {
  Modal,
  ModalButtons,
  Avatar,
  Button,
  TextField,
  Divider,
  ProgressBar
} from 'mw-style-react';
import { CREATE_ROLE, SEARCH_USERS, GET_USERS_SUGGESTION } from 'constants';
import InfiniteScroll from 'react-infinite-scroll-component';
import AppUtils from '../../../../utils/utils';
import TextButton from '../../../TextButton';
import IconButton from '../../../IconButton';
import mes from './intl';
import GroupIcon from '../../../../images/group-icon.svg';
import sModal from '../../Modal.scss'; // eslint-disable-line no-unused-vars

class CreateRole extends PureComponent {
  constructor(props) {
    super(props);
    const { data } = props;
    this.state = {
      isSubmit: false,
      name: '',
      searchName: '',
      userList: [],
      selectedUsers: [],
      error: false,
      hasMore: true,
      offset: 0
    };

    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);

    this.getSuggestion(data);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  getSuggestion(data) {
    const { dispatch } = this.props;
    dispatch({
      type: GET_USERS_SUGGESTION.REQUEST,
      payload: {
        params: {
          workspaceId: data.ext_id,
          limit: 20,
          offset: 0,
          merge: true
        },
        callback: (result, data, hasMore) => {
          if (result === 'success' && data) {
            this.setState(prev => ({
              ...prev,
              userList: data,
              offset: 0,
              hasMore
            }));
          }
        }
      }
    });
  }

  /**
   * Alert if clicked on outside of element
   */
  handleClickOutside(event) {
    const { focus } = this.state;
    if (
      focus &&
      this.wrapperRef &&
      this.wrapperRef.current &&
      !this.wrapperRef.current.contains(event.target)
    ) {
      this.setState({
        focus: false
      });
    }
  }

  fetchMore() {
    const { dispatch, data } = this.props;
    const { offset } = this.state;

    dispatch({
      type: GET_USERS_SUGGESTION.REQUEST,
      payload: {
        params: {
          workspaceId: data.ext_id,
          limit: 20,
          offset: offset + 20,
          merge: true
        },
        callback: (result, data, hasMore) => {
          if (result === 'success' && data) {
            this.setState(prev => ({
              ...prev,
              userList: [...prev.userList, ...data],
              offset: prev.offset + 20,
              hasMore
            }));
          }
        }
      }
    });
  }

  // Ф-ция локализации
  i(mesId, values) {
    return AppUtils.getMes(this.context)(mesId, values);
  }

  handleSubmit() {
    const { dispatch, onClose, data } = this.props;
    const { ext_id: workspaceId } = data || {};
    const { name, selectedUsers } = this.state;
    const userIds = selectedUsers.map(u => u.id);

    if (!name.length) {
      this.setState({
        error: true
      });
      return;
    }

    this.setState({
      isSubmit: true
    });

    dispatch({
      type: CREATE_ROLE.REQUEST,
      payload: {
        params: { workspaceId },
        body: { name: this.state.name, perms: [], users: userIds },
        callback: result => {
          if (result === 'error') {
            this.setState({
              isSubmit: false
            });
          } else {
            onClose();
          }
        }
      }
    });
  }

  handleOnChangeName(e) {
    const { value } = e;
    this.setState({
      name: value,
      error: false
    });
  }

  setFocus() {
    this.setState({
      focus: true
    });
  }

  addToActive(user) {
    const { data } = this.props;
    this.setState(state => ({
      ...state,
      focus: false,
      searchName: '',
      selectedUsers: [...state.selectedUsers, user].filter(
        (value, index, self) => self.findIndex(v => v.id === value.id) === index
      )
    }));

    this.getSuggestion(data);
  }

  handleRemoveItem(item) {
    const { id } = item || {};

    this.setState(state => ({
      ...state,
      selectedUsers: state.selectedUsers.filter(u => u.id !== id)
    }));
  }

  handleOnChangeSearch(e) {
    const { dispatch, data } = this.props;
    const { ext_id: workspaceId } = data || {};
    const { value } = e;
    this.setState({
      searchName: value
    });

    if (value.length < 3) {
      this.getSuggestion(data);
    }

    if (value.length > 2) {
      dispatch({
        type: SEARCH_USERS.REQUEST,
        payload: {
          params: {
            workspaceId,
            query: value,
            limit: 20
          },
          callback: (result, data) => {
            if (result === 'success' && data) {
              this.setState({
                userList: data
              });
            }
          }
        }
      });
    }
  }

  render() {
    const { visibility, onClose } = this.props;

    const { name, searchName, isSubmit, error, userList, selectedUsers, focus, hasMore } =
      this.state;

    const filteredUserList = userList.filter(u => !selectedUsers.find(s => s.id === u.id));

    return (
      <Modal
        visibility={visibility}
        onClose={onClose}
        styleName="sModal.modal__workspace sModal.modal__overflowVisible"
      >
        <div styleName="sModal.modal__header">
          <div styleName="sModal.modal__header__close">
            <TextButton title="Cancel" onClick={onClose} />
          </div>
          <div styleName="sModal.modal__header__title">{this.i(mes.newRole)}</div>
        </div>
        <div styleName="sModal.modal__body">
          <div styleName="sModal.modal__section">
            <div>
              <div styleName="sModal.modal__input__label">{this.i(mes.roleNameLabel)}</div>
              <TextField
                styleName="sModal.modal__input"
                value={name}
                onChange={this.handleOnChangeName.bind(this)}
                length={255}
                autoFocus
                bordered
                error={error}
                id="name"
                visibility={isSubmit ? 'disabled' : 'visible'}
              />
            </div>

            <Divider />

            <div style={{ marginTop: '20px', position: 'relative' }}>
              <div styleName="sModal.modal__input__label">Add user</div>
              <TextField
                styleName="sModal.modal__input"
                value={searchName}
                onChange={this.handleOnChangeSearch.bind(this)}
                placeholder="Search by name or email"
                leftIcon="search"
                length={255}
                bordered
                id="searchName"
                visibility={isSubmit ? 'disabled' : 'visible'}
                onFocus={this.setFocus.bind(this)}
              />
              {filteredUserList.length && focus ? (
                <div styleName="sModal.modal__input__searchItems" ref={this.wrapperRef}>
                  <ul id="scrollableModal">
                    <InfiniteScroll
                      dataLength={filteredUserList.length}
                      next={this.fetchMore.bind(this)}
                      hasMore={hasMore && !searchName.length}
                      loader={
                        <div styleName="modal__workspace__suggestion__loader">
                          <ProgressBar />
                        </div>
                      }
                      scrollableTarget="scrollableModal"
                    >
                      {filteredUserList.map(u => (
                        <li key={u.id}>
                          <div
                            styleName="sModal.modal__input__searchItemControl"
                            role="button"
                            onClick={() => this.addToActive(u)}
                          >
                            <Avatar styleName="modal__small__avatar" size="small" src={u.photo} />
                            <span>{u.name}</span>
                          </div>
                        </li>
                      ))}
                    </InfiniteScroll>
                  </ul>
                </div>
              ) : null}
            </div>
            <div>
              {selectedUsers.length ? (
                <div styleName="sModal.modal__list__items">
                  <ul>
                    {selectedUsers.map(u => (
                      <li key={u.id}>
                        <div styleName="sModal.modal__list__items__element">
                          <div styleName="sModal.modal__list__items__element__group">
                            <Avatar styleName="modal__small__avatar" size="small" src={u.photo} />
                            <span>{u.name}</span>
                          </div>
                          <IconButton icon="close" onClick={() => this.handleRemoveItem(u)} />
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              ) : (
                <div styleName="sModal.modal__list__empty">
                  <GroupIcon />
                  <span>{this.i(mes.createRoleUsersEmptyList)}</span>
                </div>
              )}
            </div>
          </div>
          <ModalButtons styleName="sModal.modal__buttons">
            <Button
              label={this.i(mes.btnCreateRole)}
              size="medium"
              onClick={this.handleSubmit.bind(this)}
              visibility={isSubmit ? 'disabled' : 'visible'}
              styleName="sModal.modal__btn sModal.wide"
            />
          </ModalButtons>
        </div>
      </Modal>
    );
  }
}

CreateRole.propTypes = {
  visibility: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  data: PropTypes.object,
  dispatch: PropTypes.func
};

CreateRole.contextTypes = {
  intl: intlShape
};

export default injectIntl(CreateRole);
