/* eslint-disable max-len */
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { intlShape, injectIntl } from 'react-intl';
import {
  Modal,
  ModalButtons,
  Avatar,
  Button,
  Divider,
  TextField,
  ProgressBar
} from 'mw-style-react';
import cn from 'classnames';
import { LEAVE_WORKSPACE, SEARCH_USERS, GET_USERS_SUGGESTION } from 'constants';
import InfiniteScroll from 'react-infinite-scroll-component';
import CustomBanner from '../../../CustomBanner';
import AppUtils from '../../../../utils/utils';
import TextButton from '../../../TextButton';
import IconButton from '../../../IconButton';
import GroupIcon from '../../../../images/group-icon.svg';
import history from '../../../../store/history';
import mes from './intl';
import sModal from '../../Modal.scss'; // eslint-disable-line no-unused-vars

class LeaveWorkspace extends PureComponent {
  constructor(props) {
    super(props);
    const { data } = props;

    this.state = {
      isSubmit: false,

      searchName: '',
      userList: [],
      selectedUsers: [],
      focus: false,
      hasMore: true,
      offset: 0,

      searchName2: '',
      selectedUsers2: [],
      focus2: false
    };

    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);

    this.wrapperRef2 = React.createRef();

    this.getSuggestion(data);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  getSuggestion(data) {
    const { dispatch } = this.props;
    dispatch({
      type: GET_USERS_SUGGESTION.REQUEST,
      payload: {
        params: {
          workspaceId: data.ext_id,
          limit: 20,
          offset: 0,
          merge: true
        },
        callback: (result, data, hasMore) => {
          if (result === 'success' && data) {
            this.setState(prev => ({
              ...prev,
              userList: data,
              offset: 0,
              hasMore
            }));
          }
        }
      }
    });
  }

  /**
   * Alert if clicked on outside of element
   */
  handleClickOutside(event) {
    const { focus, focus2 } = this.state;
    if (
      focus &&
      this.wrapperRef &&
      this.wrapperRef.current &&
      !this.wrapperRef.current.contains(event.target)
    ) {
      this.setState({
        focus: false
      });
    }

    if (
      focus2 &&
      this.wrapperRef2 &&
      this.wrapperRef2.current &&
      !this.wrapperRef2.current.contains(event.target)
    ) {
      this.setState({
        focus2: false
      });
    }
  }

  fetchMore() {
    const { dispatch, data } = this.props;
    const { offset } = this.state;

    dispatch({
      type: GET_USERS_SUGGESTION.REQUEST,
      payload: {
        params: {
          workspaceId: data.ext_id,
          limit: 20,
          offset: offset + 20,
          merge: true
        },
        callback: (result, data, hasMore) => {
          if (result === 'success' && data) {
            this.setState(prev => ({
              ...prev,
              userList: [...prev.userList, ...data],
              offset: prev.offset + 20,
              hasMore
            }));
          }
        }
      }
    });
  }

  // Ф-ция локализации
  i(mesId, values) {
    return AppUtils.getMes(this.context)(mesId, values);
  }

  setFocus() {
    this.setState({
      focus: true,
      focus2: false
    });
  }

  setFocus2() {
    this.setState({
      focus2: true,
      focus: false
    });
  }

  addToActive(user) {
    const {
      auth: { user_id: userId },
      data
    } = this.props;
    if (userId !== user.id) {
      this.setState(state => ({
        ...state,
        searchName: '',
        focus: false,
        selectedUsers: [user]
      }));

      this.getSuggestion(data);
    }
  }

  addToActive2(user) {
    const {
      auth: { user_id: userId },
      data
    } = this.props;

    if (userId !== user.id) {
      this.setState(
        prevState => {
          const userAlreadySelected = prevState.selectedUsers2.some(su => su.id === user.id);

          if (!userAlreadySelected) {
            return {
              ...prevState,
              searchName2: '',
              focus2: false,
              selectedUsers2: [...prevState.selectedUsers2, user]
            };
          }

          return {
            ...prevState,
            searchName2: '',
            focus2: false
          };
        },
        () => {
          this.getSuggestion(data);
        }
      );
    }
  }

  handleRemoveItem(item) {
    const { id } = item || {};

    this.setState(state => ({
      ...state,
      selectedUsers: state.selectedUsers.filter(u => u.id !== id)
    }));
  }

  handleRemoveItem2(item) {
    const { id } = item || {};

    this.setState(state => ({
      ...state,
      selectedUsers2: state.selectedUsers2.filter(u => u.id !== id)
    }));
  }

  handleOnChangeSearch(e) {
    const { dispatch, data } = this.props;
    const { ext_id: workspaceId } = data || {};
    const { value } = e;
    this.setState({
      searchName: value
    });

    if (value.length < 3) {
      this.getSuggestion(data);
    }

    if (value.length > 2) {
      dispatch({
        type: SEARCH_USERS.REQUEST,
        payload: {
          params: {
            workspaceId,
            query: value,
            limit: 20
          },
          callback: (result, data) => {
            if (result === 'success' && data) {
              this.setState({
                userList: data
              });
            }
          }
        }
      });
    } else {
      this.setState({
        userList: []
      });
    }
  }

  handleOnChangeSearch2(e) {
    const { dispatch, data } = this.props;
    const { ext_id: workspaceId } = data || {};
    const { value } = e;
    this.setState({
      searchName2: value
    });

    if (value.length < 3) {
      this.getSuggestion(data);
    }

    if (value.length > 2) {
      dispatch({
        type: SEARCH_USERS.REQUEST,
        payload: {
          params: {
            workspaceId,
            query: value,
            limit: 20
          },
          callback: (result, data) => {
            if (result === 'success' && data) {
              this.setState({
                userList: data
              });
            }
          }
        }
      });
    } else {
      this.setState({
        userList: []
      });
    }
  }

  handleSubmit() {
    const {
      dispatch,
      onClose,
      data: { ext_id: workspaceId },
      auth: { user_id: userId }
    } = this.props;
    const { selectedUsers, selectedUsers2 } = this.state;
    const ownerId = selectedUsers.length ? selectedUsers[0].id : undefined;
    const propOwnerIds = selectedUsers2.map(s => s.id);

    this.setState({
      isSubmit: true
    });

    dispatch({
      type: LEAVE_WORKSPACE.REQUEST,
      payload: {
        params: {
          workspaceId,
          userId,
          ownerId,
          propOwnerIds
        },
        callback: result => {
          if (result === 'error') {
            this.setState({
              isSubmit: false
            });
          } else {
            history().push('/workspace');
            onClose();
          }
        }
      }
    });
  }

  render() {
    const { visibility, onClose } = this.props;

    const {
      isSubmit,
      searchName,
      searchName2,
      selectedUsers2,
      userList,
      selectedUsers,
      focus,
      focus2,
      hasMore
    } = this.state;
    const {
      data: { owners, name },
      auth
    } = this.props;
    const { user_id: userId } = auth || {};

    const isCurrentUserOnlyOwner = owners.length === 1 && owners[0].id === userId;

    const filteredUserList = userList.filter(u => !selectedUsers.find(s => s.id === u.id));
    const filteredUserList2 = userList.filter(u => !selectedUsers2.some(el => el.id === u.id));

    return (
      <Modal
        visibility={visibility}
        onClose={onClose}
        styleName="sModal.modal__workspace sModal.modal__overflowVisible"
      >
        <div styleName="sModal.modal__header">
          <div styleName="sModal.modal__header__close">
            <TextButton title="Cancel" onClick={onClose} />
          </div>
          <div styleName="sModal.modal__header__title">{this.i(mes.leaveWorkspace)}</div>
        </div>
        <div styleName="sModal.modal__body">
          {isCurrentUserOnlyOwner ? (
            <div styleName="sModal.modal__section">
              <div styleName="sModal.modal__description">
                Are you sure you want to leave <strong>{name}</strong> workspace? <br />
                You’ll be able to join later via invite.
              </div>
              <div style={{ marginBottom: 16 }}>
                <CustomBanner text={this.i(mes.bannerCautionLeave)} color="orange" />
              </div>
              <Divider />
              <div style={{ marginTop: '20px', position: 'relative' }}>
                <div styleName="sModal.modal__input__label">Add new owner</div>
                <TextField
                  styleName="sModal.modal__input"
                  value={searchName}
                  onChange={this.handleOnChangeSearch.bind(this)}
                  placeholder="Search by name or email"
                  leftIcon="search"
                  length={255}
                  bordered
                  id="searchName"
                  visibility={isSubmit ? 'disabled' : 'visible'}
                  onFocus={this.setFocus.bind(this)}
                />
                {focus && filteredUserList.length ? (
                  <div styleName="sModal.modal__input__searchItems" ref={this.wrapperRef}>
                    <ul id="scrollableModal">
                      <InfiniteScroll
                        dataLength={filteredUserList.length}
                        next={this.fetchMore.bind(this)}
                        hasMore={hasMore && !searchName.length}
                        loader={
                          <div styleName="modal__workspace__suggestion__loader">
                            <ProgressBar />
                          </div>
                        }
                        scrollableTarget="scrollableModal"
                      >
                        {filteredUserList.map(u => (
                          <li
                            key={u.id}
                            styleName={cn({
                              'sModal.listItem__disabled': u.id === userId
                            })}
                          >
                            <div
                              styleName="sModal.modal__input__searchItemControl"
                              role="button"
                              tabIndex={0}
                              onClick={() => this.addToActive(u)}
                              onKeyDown={() => this.addToActive(u)}
                            >
                              <Avatar styleName="modal__small__avatar" size="small" src={u.photo} />
                              <span>{u.name}</span>
                            </div>
                          </li>
                        ))}
                      </InfiniteScroll>
                    </ul>
                  </div>
                ) : null}
              </div>
              <div>
                {selectedUsers.length ? (
                  <div styleName="sModal.modal__list__items">
                    <ul>
                      {selectedUsers.map(u => (
                        <li key={u.id}>
                          <div styleName="sModal.modal__list__items__element">
                            <div styleName="sModal.modal__list__items__element__group">
                              <Avatar styleName="modal__small__avatar" size="small" src={u.photo} />
                              <span>{u.name}</span>
                            </div>
                            <IconButton icon="close" onClick={() => this.handleRemoveItem(u)} />
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : null}
              </div>
            </div>
          ) : (
            <div styleName="sModal.modal__section">
              <div styleName="sModal.modal__description">
                Are you sure you want to leave <strong>{name} workspace</strong>? <br />
                This action is irreversible.
                <div style={{ color: 'rgba(126, 138, 154, 1)' }}>
                  You’ll be able to join later via invite.
                </div>
              </div>
              <div style={{ marginBottom: 16 }}>
                <CustomBanner text={this.i(mes.bannerCautionLeave)} color="orange" />
              </div>
            </div>
          )}
          <div styleName="sModal.modal__section" style={{ marginTop: 0 }}>
            <div style={{ position: 'relative' }}>
              <div styleName="sModal.modal__input__label">
                Matching access rights to all your items
              </div>
              <TextField
                styleName="sModal.modal__input"
                value={searchName2}
                onChange={this.handleOnChangeSearch2.bind(this)}
                placeholder="Search by name or email"
                leftIcon="search"
                length={255}
                bordered
                id="searchName"
                visibility={isSubmit ? 'disabled' : 'visible'}
                onFocus={this.setFocus2.bind(this)}
              />
              {focus2 && filteredUserList2.length ? (
                <div styleName="sModal.modal__input__searchItems" ref={this.wrapperRef2}>
                  <ul id="scrollableModal">
                    <InfiniteScroll
                      dataLength={filteredUserList2.length}
                      next={this.fetchMore.bind(this)}
                      hasMore={hasMore && !searchName2.length}
                      loader={
                        <div styleName="modal__workspace__suggestion__loader">
                          <ProgressBar />
                        </div>
                      }
                      scrollableTarget="scrollableModal"
                    >
                      {filteredUserList2.map(u => (
                        <li
                          key={u.id}
                          styleName={cn({
                            'sModal.listItem__disabled': u.id === userId
                          })}
                        >
                          <div
                            styleName="sModal.modal__input__searchItemControl"
                            role="button"
                            tabIndex={0}
                            onClick={() => this.addToActive2(u)}
                            onKeyDown={() => this.addToActive2(u)}
                          >
                            <Avatar styleName="modal__small__avatar" size="small" src={u.photo} />
                            <span>{u.name}</span>
                          </div>
                        </li>
                      ))}
                    </InfiniteScroll>
                  </ul>
                </div>
              ) : null}
            </div>
            <div>
              <div styleName="sModal.modal__list__items">
                {selectedUsers2.length ? (
                  <ul>
                    {selectedUsers2.map(u => (
                      <li key={u.id}>
                        <div styleName="sModal.modal__list__items__element">
                          <div styleName="sModal.modal__list__items__element__group">
                            <Avatar styleName="modal__small__avatar" size="small" src={u.photo} />
                            <span>{u.name}</span>
                          </div>
                          <IconButton icon="close" onClick={() => this.handleRemoveItem2(u)} />
                        </div>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <div styleName="sModal.modal__list__empty">
                    <GroupIcon />
                    <span>Choose users from the list</span>
                  </div>
                )}
              </div>
            </div>
          </div>
          <ModalButtons styleName="sModal.modal__buttons">
            <Button
              label={this.i(mes.btnLeave)}
              size="medium"
              onClick={this.handleSubmit.bind(this)}
              styleName={cn('sModal.modal__btn sModal.red sModal.wide', {
                'sModal.red__disabled':
                  isSubmit ||
                  (isCurrentUserOnlyOwner && !selectedUsers.length) ||
                  !selectedUsers2.length
              })}
              visibility={
                isSubmit ||
                (isCurrentUserOnlyOwner && !selectedUsers.length) ||
                !selectedUsers2.length
                  ? 'disabled'
                  : 'visible'
              }
            />
          </ModalButtons>
        </div>
      </Modal>
    );
  }
}

LeaveWorkspace.propTypes = {
  visibility: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired
};

LeaveWorkspace.contextTypes = {
  intl: intlShape
};

export default injectIntl(LeaveWorkspace);
