/* eslint-disable max-len */
import { Utils } from 'mw-style-react';

/**
 * Утилиты
 */
const AppUtils = {
  // Получить сообщение локализации
  getMes(context) {
    const ct = context.intl;
    return function res(mesId, values) {
      try {
        return ct.formatMessage(mesId, values);
      } catch (e) {
        return mesId;
      }
    };
  },

  formatPrice(price) {
    if (price === 0) {
      return 'Free';
    }

    const formattedPrice = `$${price.toFixed(2)}`;
    return formattedPrice;
  },

  // Сформировать url с get параметрами
  makeUrl(url, params) {
    if (!Object.keys(params).length) return url;
    const divider = url.includes('?') ? '&' : '?';
    return `${url}${divider}${Utils.serialize(params)}`;
  },

  makeImgUrl(img) {
    let fullPath = img;
    if (!/^(http|https):\//.test(img)) {
      fullPath = `/static/${img}`;
    }
    return fullPath;
  },

  // валидация и кориктеровка redirect uri
  controlRedirectUrl(redirectUrl, whitelist) {
    if (!redirectUrl) return '';
    // используем только первую часть redirect_uri
    const first = redirectUrl.split('?');

    if (whitelist && whitelist.length > 0) {
      let isRedirect = false;
      whitelist.forEach(item => {
        if (first[0].indexOf(item) !== -1) {
          isRedirect = true;
        }
      });
      if (!isRedirect) return '';
    }

    return redirectUrl.substring(0, 4) === 'http' ? redirectUrl : '';
  },

  validateEmail(email) {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  },

  validateUrl(url) {
    return String(url)
      .toLowerCase()
      .match(
        /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/
      );
  },

  isValidHttpUrl(string) {
    let url;
    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }
    return url.protocol === 'http:' || url.protocol === 'https:';
  },

  transformURL(obj, workspaceId, enable) {
    const whitelist = { corezoid: 'corezoid', control: 'control' };
    if (enable && obj.name === whitelist.corezoid && workspaceId) {
      return `${this.removeTrailingSlash(obj.homepage)}/${workspaceId}/workspace/project/0`;
    }

    if (enable && obj.name === whitelist.control && workspaceId) {
      return `${this.removeTrailingSlash(obj.homepage)}/events/${workspaceId}`;
    }

    return obj.redirect_uri || obj.homepage;
  },

  removeTrailingSlash(url) {
    if (typeof url !== 'string') {
      throw new Error('Input must be a string');
    }
    return url.replace(/\/+$/, '');
  },

  intlParse({ intl = {}, mesId = {} }) {
    const { messages } = intl;
    return messages[mesId.id] || mesId.defaultMessage;
  },

  download(url, fileName) {
    fetch(url).then(t =>
      t.blob().then(b => {
        const a = document.createElement('a');
        a.href = URL.createObjectURL(b);
        a.setAttribute('download', fileName);
        a.click();
      })
    );
  }
};

export default AppUtils;
