import { defineMessages } from 'react-intl';

export default defineMessages({
  field_required: {
    id: 'field_required',
    defaultMessage: 'Field is required'
  },
  email: {
    id: 'email',
    defaultMessage: 'Email'
  },
  ldap: {
    id: 'ldap',
    defaultMessage: 'Ldap'
  },
  password: {
    id: 'password',
    defaultMessage: 'Password'
  },
  userName: {
    id: 'userName',
    defaultMessage: 'Username'
  },
  fullName: {
    id: 'fullName',
    defaultMessage: 'Full name'
  },
  password_error_min: {
    id: 'password_error_min',
    defaultMessage: 'Password needs to be more than {count} symbols'
  },
  password_error_max: {
    id: 'password_error_max',
    defaultMessage: 'Password needs to be less than {count} symbols'
  },
  userName_error_min: {
    id: 'userName_error_min',
    defaultMessage: 'Username needs to be more than {count} symbols'
  },
  userName_error_max: {
    id: 'userName_error_max',
    defaultMessage: 'Username needs to be less than {count} symbols'
  },
  credentials_error: {
    id: 'credentials_error',
    defaultMessage: 'Invalid login or password'
  },
  email_error_already_exist: {
    id: 'email_error_already_exist',
    defaultMessage: 'User with this email address is already exist. Log In?'
  },
  email_error_not_found: {
    id: 'email_error_not_found',
    defaultMessage: 'A user with this email does not exist'
  },
  email_error_is_not_valid: {
    id: 'email_error_is_not_valid',
    defaultMessage: 'E-mail is not valid'
  },
  ldap_error_is_not_valid: {
    id: 'ldap_error_is_not_valid',
    defaultMessage: 'Ldap is not valid'
  },
  email_error_must_contain_at: {
    id: 'email_error_must_contain_at',
    defaultMessage: 'An e-mail address must contain a single @'
  },
  email_helper: {
    id: 'email_helper',
    defaultMessage: "We'll send confirmation email on this address"
  },
  lowercase_symbol: {
    id: 'lowercase_symbol',
    defaultMessage: 'Lowercase symbol'
  },
  uppercase_symbol: {
    id: 'uppercase_symbol',
    defaultMessage: 'Uppercase symbol'
  },
  special_symbol: {
    id: 'special_symbol',
    defaultMessage: 'Special symbol'
  },
  numbers_symbol: {
    id: 'numbers_symbol',
    defaultMessage: 'Numbers symbol'
  },
  symbols_minimum: {
    id: 'symbols_minimum',
    defaultMessage: '9 symbols minimum'
  },
  authorization: {
    id: 'authorization',
    defaultMessage: 'Authorization'
  },
  profile: {
    id: 'profile',
    defaultMessage: 'Profile'
  },
  applications: {
    id: 'applications',
    defaultMessage: 'Applications'
  },
  workspace: {
    id: 'workspace',
    defaultMessage: 'Workspace'
  },
  billing: {
    id: 'billing',
    defaultMessage: 'Billing'
  },
  limits: {
    id: 'limits',
    defaultMessage: 'Limits'
  },
  create: {
    id: 'create',
    defaultMessage: 'Register a new application'
  },
  placeholderEmail: {
    id: 'placeholderEmail',
    defaultMessage: 'name@mail.com'
  },
  placeholderPassword: {
    id: 'placeholderPassword',
    defaultMessage: 'Min. 8 characters'
  }
});
