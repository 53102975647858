import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { intlShape, injectIntl } from 'react-intl';
import { Modal, ModalButtons, ModalContent, Button, ProgressBar, Label } from 'mw-style-react';
import { DEL_APPLICATION } from 'constants';
import AppUtils from '../../../../utils/utils';
import mes from './intl';
import sModal from '../../Modal.scss'; // eslint-disable-line no-unused-vars
import sLocal from './DeleteApplication.scss'; // eslint-disable-line no-unused-vars

class DeleteApplication extends PureComponent {
  // Ф-ция локализации
  i(mesId, values) {
    return AppUtils.getMes(this.context)(mesId, values);
  }

  handleDelete(id) {
    this.props.dispatch({
      type: DEL_APPLICATION.REQUEST,
      payload: id
    });
  }

  render() {
    const { visibility, onClose, isSubmit, data } = this.props;

    return (
      <Modal
        styleName="sLocal.titleHead"
        visibility={visibility}
        onClose={onClose}
        label={this.i(mes.deleteApplication)}
      >
        <ModalContent styleName="sModal.modal__content sLocal.content">
          <div>
            <div key={1} styleName="sLocal.row sLocal.info">
              <Label value={this.i(mes.deleteApplication)} />
              &nbsp;
              <Label fontWeight="semibold" value={data.full_name} />
            </div>
          </div>
        </ModalContent>
        <ModalButtons styleName="sModal.modal__buttons">
          <Button
            label={this.i(mes.cancel)}
            size="medium"
            type="text"
            onClick={onClose}
            visibility={isSubmit ? 'disabled' : 'visible'}
          />
          <Button
            label={this.i(mes.delete)}
            size="medium"
            type="text"
            onClick={() => this.handleDelete(data.obj_id)}
            visibility={isSubmit ? 'disabled' : 'visible'}
          />
          <ProgressBar
            styleName="sModal.modal__loader"
            type="circle"
            size="small"
            visibility={isSubmit ? 'visible' : 'hidden'}
          />
        </ModalButtons>
      </Modal>
    );
  }
}

DeleteApplication.propTypes = {
  visibility: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  isSubmit: PropTypes.bool,
  data: PropTypes.object,
  dispatch: PropTypes.func
};

DeleteApplication.contextTypes = {
  intl: intlShape
};

export default injectIntl(DeleteApplication);
