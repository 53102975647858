/* eslint-disable no-confusing-arrow */
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { intlShape, injectIntl } from 'react-intl';
import { Modal, ModalButtons, Button, TextField } from 'mw-style-react';
import connect from 'react-redux/es/connect/connect';
import { MANAGE_USER_ROLES, GET_ROLES } from 'constants';
import AppUtils from '../../../../utils/utils';
import TextButton from '../../../TextButton';
import IconButton from '../../../IconButton';
import mes from './intl';

import sModal from '../../Modal.scss'; // eslint-disable-line no-unused-vars

class ManageUserRoles extends PureComponent {
  constructor(props) {
    super(props);
    const { data, dispatch } = props;
    this.state = {
      isSubmit: false,
      searchName: '',
      roleList: [],
      selectedRoles: [...data.roles.map(r => ({ ...r, active: true }))],
      initialRoles: [...data.roles.map(r => ({ ...r, active: true }))]
    };

    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);

    dispatch({
      type: GET_ROLES.REQUEST,
      payload: {
        params: {
          workspaceId: data.workspaceId
        }
      }
    });
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  /**
   * Alert if clicked on outside of element
   */
  handleClickOutside(event) {
    const { focus } = this.state;
    if (focus && this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      this.setState({
        focus: false
      });
    }
  }

  // Ф-ция локализации
  i(mesId, values) {
    return AppUtils.getMes(this.context)(mesId, values);
  }

  handleSubmit() {
    const { dispatch, onClose, data } = this.props;
    const { workspaceId, id } = data || {};
    const { selectedRoles = [], initialRoles = [] } = this.state;
    const deleted = initialRoles.filter(iu => {
      const selected = selectedRoles.find(e => e.id === iu.id) || {};
      return !selected.active;
    });
    const added = selectedRoles.filter(iu => {
      if (!iu.active) return false;
      const selected = initialRoles.find(e => e.id === iu.id);
      if (!selected) return true;
      return false;
    });
    const roles = [
      ...deleted.map(u => ({ id: u.id, active: false })),
      ...added.map(u => ({ id: u.id, active: true }))
    ];

    this.setState({
      isSubmit: true
    });

    if (!roles.length) {
      onClose();
      return;
    }

    dispatch({
      type: MANAGE_USER_ROLES.REQUEST,
      payload: {
        params: { workspaceId, userId: id, final: selectedRoles.filter(r => r.active) },
        body: roles,
        callback: result => {
          if (result === 'error') {
            this.setState({
              isSubmit: false
            });
          } else {
            setTimeout(() => {
              onClose();
            }, 100);
          }
        }
      }
    });
  }

  setFocus() {
    this.setState({
      focus: true
    });
  }

  addToActive(role) {
    this.setState(state => ({
      ...state,
      focus: false,
      searchName: '',
      selectedRoles: [...state.selectedRoles, role]
        .filter((value, index, self) => self.findIndex(v => v.id === value.id) === index)
        .map(u => (u.id !== role.id ? u : { ...u, active: true }))
    }));
  }

  handleRemoveItem(item) {
    const { id } = item || {};

    this.setState(state => ({
      ...state,
      selectedRoles: state.selectedRoles.map(u => (u.id !== id ? u : { ...u, active: false }))
    }));
  }

  handleOnChangeSearch(e) {
    const { value } = e;
    this.setState({
      searchName: value
    });
  }

  render() {
    const { visibility, onClose, data, roles } = this.props;

    const { list } = roles;

    const { searchName, isSubmit, selectedRoles, focus } = this.state;
    const filteredSelectedRoles = selectedRoles.filter(s => s.active);

    const filteredRoleList = list
      ? list.filter(
          s => s.name.startsWith(searchName) && !filteredSelectedRoles.find(r => r.id === s.id)
        )
      : [];

    return (
      <Modal
        visibility={visibility}
        onClose={onClose}
        styleName="sModal.modal__workspace sModal.modal__overflowVisible"
      >
        <div styleName="sModal.modal__header">
          <div styleName="sModal.modal__header__close">
            <TextButton title="Cancel" onClick={onClose} />
          </div>
          <div styleName="sModal.modal__header__title">{this.i(mes.manageUserRolesHeader)}</div>
        </div>
        <div styleName="sModal.modal__body">
          <div styleName="sModal.modal__section">
            <div styleName="sModal.modal__description">
              Select roles to assign for <strong>{data.name}</strong>
            </div>

            <div style={{ marginTop: '20px', position: 'relative' }}>
              <div styleName="sModal.modal__input__label">Add role</div>
              <TextField
                styleName="sModal.modal__input"
                value={searchName}
                onChange={this.handleOnChangeSearch.bind(this)}
                placeholder="Search by name"
                leftIcon="search"
                length={255}
                bordered
                id="searchName"
                visibility={isSubmit ? 'disabled' : 'visible'}
                onFocus={this.setFocus.bind(this)}
              />
              {filteredRoleList.length && focus ? (
                <div styleName="sModal.modal__input__searchItems" ref={this.wrapperRef}>
                  <ul>
                    {filteredRoleList.map(u => (
                      <li key={u.id}>
                        <div
                          styleName="sModal.modal__input__searchItemControl"
                          role="button"
                          onClick={() => this.addToActive(u)}
                        >
                          <span>{u.name}</span>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              ) : null}
            </div>
            <div>
              {filteredSelectedRoles.length ? (
                <div styleName="sModal.modal__list__items">
                  <ul>
                    {filteredSelectedRoles.map(u => (
                      <li key={u.id}>
                        <div styleName="sModal.modal__list__items__element">
                          <div styleName="sModal.modal__list__items__element__group">
                            <span>{u.name}</span>
                          </div>
                          <IconButton icon="close" onClick={() => this.handleRemoveItem(u)} />
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              ) : (
                <div styleName="sModal.modal__list__empty">
                  <span styleName="sModal.modal__list__empty__danger">
                    {this.i(mes.manageUserRolesEmptyList1)} <br />
                    {this.i(mes.manageUserRolesEmptyList2)}
                  </span>
                </div>
              )}
            </div>
          </div>
          <ModalButtons styleName="sModal.modal__buttons">
            <Button
              label={this.i(mes.manageUserRolesButton)}
              size="medium"
              onClick={this.handleSubmit.bind(this)}
              visibility={!filteredSelectedRoles.length || isSubmit ? 'disabled' : 'visible'}
              styleName="sModal.modal__btn sModal.wide"
            />
          </ModalButtons>
        </div>
      </Modal>
    );
  }
}

ManageUserRoles.propTypes = {
  visibility: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  data: PropTypes.object,
  roles: PropTypes.object,
  dispatch: PropTypes.func
};

ManageUserRoles.contextTypes = {
  intl: intlShape
};

const mapStateToProps = ({ roles }) => ({
  roles
});

export default injectIntl(connect(mapStateToProps)(ManageUserRoles));
