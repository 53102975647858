import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Switch, Route, Redirect } from 'react-router-dom';
import 'react-tippy/dist/tippy.css';
import routes from '../../routes';
import '../../styles/core.scss';

const mapStateToProps = state => ({
  config: state.config
});

function RouteWithLayout({ layout: Layout, component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={matchProps => (
        <Layout>
          <Component {...matchProps} />
        </Layout>
      )}
    />
  );
}

RouteWithLayout.propTypes = {
  layout: PropTypes.func.isRequired,
  component: PropTypes.func.isRequired
};

function RootLayout({ config }) {
  if (!Object.keys(config).length) return null;
  return (
    <Switch>
      {routes.map((params, index) => (
        <RouteWithLayout key={index} {...params} />
      ))}
      <Redirect from="*" to="/not_found" />
    </Switch>
  );
}

RootLayout.propTypes = {
  config: PropTypes.object
};

export default withRouter(connect(mapStateToProps)(RootLayout));
