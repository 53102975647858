/* eslint-disable max-len */
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import connect from 'react-redux/es/connect/connect';
import { intlShape, injectIntl } from 'react-intl';
import {
  Modal,
  ModalButtons,
  Avatar,
  Button,
  TextField,
  ProgressBar,
  Checkbox,
  Label
} from 'mw-style-react';
import cn from 'classnames';
import { MODIFY_GROUP_OWNER, SEARCH_USERS, GET_USERS_SUGGESTION } from 'constants';
import InfiniteScroll from 'react-infinite-scroll-component';
import AppUtils from '../../../../utils/utils';
import TextButton from '../../../TextButton';
import IconButton from '../../../IconButton';
import history from '../../../../store/history';
import mes from './intl';
import sModal from '../../Modal.scss'; // eslint-disable-line no-unused-vars

class ChangeGroupOwnership extends PureComponent {
  constructor(props) {
    super(props);
    const { data } = props;

    this.state = {
      isSubmit: false,
      searchName: '',
      userList: [],
      selectedUsers: [],
      leave: true,
      focus: false,
      hasMore: true,
      offset: 0
    };

    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);

    this.getSuggestion(data);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  getSuggestion(data) {
    const { dispatch } = this.props;
    dispatch({
      type: GET_USERS_SUGGESTION.REQUEST,
      payload: {
        params: {
          workspaceId: data.workspaceId,
          limit: 20,
          offset: 0,
          merge: true
        },
        callback: (result, data, hasMore) => {
          if (result === 'success' && data) {
            this.setState(prev => ({
              ...prev,
              userList: data,
              offset: 0,
              hasMore
            }));
          }
        }
      }
    });
  }

  /**
   * Alert if clicked on outside of element
   */
  handleClickOutside(event) {
    const { focus } = this.state;
    if (
      focus &&
      this.wrapperRef &&
      this.wrapperRef.current &&
      !this.wrapperRef.current.contains(event.target)
    ) {
      this.setState({
        focus: false
      });
    }
  }

  handleOnChangeCheckbox() {
    this.setState(prev => ({
      leave: !prev.leave
    }));
  }

  fetchMore() {
    const { dispatch, data } = this.props;
    const { offset } = this.state;

    dispatch({
      type: GET_USERS_SUGGESTION.REQUEST,
      payload: {
        params: {
          workspaceId: data.workspaceId,
          limit: 20,
          offset: offset + 20,
          merge: true
        },
        body: {},
        callback: (result, data, hasMore) => {
          if (result === 'success' && data) {
            this.setState(prev => ({
              ...prev,
              userList: [...prev.userList, ...data],
              offset: prev.offset + 20,
              hasMore
            }));
          }
        }
      }
    });
  }

  // Ф-ция локализации
  i(mesId, values) {
    return AppUtils.getMes(this.context)(mesId, values);
  }

  setFocus() {
    this.setState({
      focus: true
    });
  }

  addToActive(user) {
    const {
      auth: { user_id: userId },
      data
    } = this.props;
    if (userId !== user.id) {
      this.setState(state => ({
        ...state,
        searchName: '',
        focus: false,
        selectedUsers: [user]
      }));

      this.getSuggestion(data);
    }
  }

  handleRemoveItem(item) {
    const { id } = item || {};

    this.setState(state => ({
      ...state,
      selectedUsers: state.selectedUsers.filter(u => u.id !== id)
    }));
  }

  handleOnChangeSearch(e) {
    const { dispatch, data } = this.props;
    const { workspaceId } = data || {};
    const { value } = e;
    this.setState({
      searchName: value
    });

    if (value.length < 3) {
      this.getSuggestion(data);
    }

    if (value.length > 2) {
      dispatch({
        type: SEARCH_USERS.REQUEST,
        payload: {
          params: {
            workspaceId,
            query: value,
            limit: 20
          },
          callback: (result, data) => {
            if (result === 'success' && data) {
              this.setState({
                userList: data
              });
            }
          }
        }
      });
    } else {
      this.setState({
        userList: []
      });
    }
  }

  handleSubmit() {
    const {
      dispatch,
      onClose,
      data: { id, workspaceId },
      auth,
      workspace
    } = this.props;
    const { permissions } = workspace || {};
    const { groupManaging, allGroupManaging } = permissions || {};
    const { selectedUsers, leave } = this.state;
    const owner = selectedUsers.length ? selectedUsers[0] : undefined;
    const canManageGroups = groupManaging || allGroupManaging;

    this.setState({
      isSubmit: true
    });

    dispatch({
      type: MODIFY_GROUP_OWNER.REQUEST,
      payload: {
        params: { workspaceId, id },
        body: {
          leave,
          owner_id: owner.id
        },
        props: { newOwner: owner, oldOwner: auth, canManageGroups },
        callback: result => {
          if (result === 'error') {
            this.setState({
              isSubmit: false
            });
          } else {
            if (leave && !canManageGroups) history().push(`/workspace/${workspaceId}/group`);
            onClose();
          }
        }
      }
    });
  }

  render() {
    const { visibility, onClose } = this.props;

    const { isSubmit, searchName, userList, selectedUsers, focus, hasMore, leave } = this.state;
    const {
      data: { name },
      auth
    } = this.props;
    const { user_id: userId } = auth || {};

    const filteredUserList = userList.filter(u => !selectedUsers.find(s => s.id === u.id));

    return (
      <Modal
        visibility={visibility}
        onClose={onClose}
        styleName="sModal.modal__workspace sModal.modal__overflowVisible"
      >
        <div styleName="sModal.modal__header">
          <div styleName="sModal.modal__header__close">
            <TextButton title="Cancel" onClick={onClose} />
          </div>
          <div styleName="sModal.modal__header__title">{this.i(mes.changeGroupOwnership)}</div>
        </div>
        <div styleName="sModal.modal__body">
          <div styleName="sModal.modal__section">
            <div styleName="sModal.modal__description">
              Select user to transfer group <strong>{name}</strong> ownership. <br />
            </div>
            <div style={{ marginTop: '20px', position: 'relative' }}>
              <div styleName="sModal.modal__input__label">Select user</div>
              <TextField
                styleName="sModal.modal__input"
                value={searchName}
                onChange={this.handleOnChangeSearch.bind(this)}
                placeholder={this.i(mes.changeGroupOwnershipLabel)}
                leftIcon="search"
                length={255}
                bordered
                id="searchName"
                visibility={isSubmit ? 'disabled' : 'visible'}
                onFocus={this.setFocus.bind(this)}
              />
              {focus && filteredUserList.length ? (
                <div styleName="sModal.modal__input__searchItems" ref={this.wrapperRef}>
                  <ul id="scrollableModal">
                    <InfiniteScroll
                      dataLength={filteredUserList.length}
                      next={this.fetchMore.bind(this)}
                      hasMore={hasMore && !searchName.length}
                      loader={
                        <div styleName="modal__workspace__suggestion__loader">
                          <ProgressBar />
                        </div>
                      }
                      scrollableTarget="scrollableModal"
                    >
                      {filteredUserList.map(u => (
                        <li
                          key={u.id}
                          styleName={cn({
                            'sModal.listItem__disabled': u.id === userId
                          })}
                        >
                          <div
                            styleName="sModal.modal__input__searchItemControl"
                            role="button"
                            onClick={() => this.addToActive(u)}
                          >
                            <Avatar styleName="modal__small__avatar" size="small" src={u.photo} />
                            <span>{u.name}</span>
                          </div>
                        </li>
                      ))}
                    </InfiniteScroll>
                  </ul>
                </div>
              ) : null}
            </div>
            <div>
              {selectedUsers.length ? (
                <div styleName="sModal.modal__list__items">
                  <ul>
                    {selectedUsers.map(u => (
                      <li key={u.id}>
                        <div styleName="sModal.modal__list__items__element">
                          <div styleName="sModal.modal__list__items__element__group">
                            <Avatar styleName="modal__small__avatar" size="small" src={u.photo} />
                            <span>{u.name}</span>
                          </div>
                          <IconButton icon="close" onClick={() => this.handleRemoveItem(u)} />
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              ) : null}
            </div>
            <div>
              <div style={{ marginBottom: '24px' }}>
                <Checkbox
                  value={leave}
                  onChange={() => this.handleOnChangeCheckbox()}
                  styleName="sModal.modal__checkboxGroup__checkbox"
                >
                  <div styleName="sModal.modal__input__label__ellipsis">
                    <Label value="Leave group after transfer" />
                  </div>
                </Checkbox>
              </div>
            </div>
          </div>
          <ModalButtons styleName="sModal.modal__buttons">
            <Button
              label={this.i(mes.btnChangeGroupOwnership)}
              size="medium"
              onClick={this.handleSubmit.bind(this)}
              styleName={cn('sModal.modal__btn sModal.wide')}
              visibility={isSubmit || !selectedUsers.length ? 'disabled' : 'visible'}
            />
          </ModalButtons>
        </div>
      </Modal>
    );
  }
}

ChangeGroupOwnership.propTypes = {
  visibility: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  data: PropTypes.object,
  auth: PropTypes.object,
  dispatch: PropTypes.func,
  workspace: PropTypes.object
};

ChangeGroupOwnership.contextTypes = {
  intl: intlShape
};

const mapStateToProps = ({ workspace }) => ({
  workspace
});

export default injectIntl(connect(mapStateToProps)(ChangeGroupOwnership));
