import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { intlShape, injectIntl } from 'react-intl';
import { Modal, ModalButtons, Button, TextField, Divider } from 'mw-style-react';
import { CREATE_GROUP } from 'constants';
import AppUtils from '../../../../utils/utils';
import TextButton from '../../../TextButton';
import mes from './intl';

import sModal from '../../Modal.scss'; // eslint-disable-line no-unused-vars

class CreateGroup extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isSubmit: false,
      name: '',
      error: false
    };
  }

  // Ф-ция локализации
  i(mesId, values) {
    return AppUtils.getMes(this.context)(mesId, values);
  }

  handleSubmit() {
    const { dispatch, onClose, data } = this.props;
    const { ext_id: workspaceId } = data || {};
    const { name } = this.state;

    if (!name.length) {
      this.setState({
        error: true
      });
      return;
    }

    this.setState({
      isSubmit: true
    });

    dispatch({
      type: CREATE_GROUP.REQUEST,
      payload: {
        params: { workspaceId },
        body: { name: this.state.name, users: [] },
        callback: result => {
          if (result === 'error') {
            this.setState({
              isSubmit: false
            });
          } else {
            onClose();
          }
        }
      }
    });
  }

  handleOnChangeName(e) {
    const { value } = e;
    this.setState({
      name: value,
      error: false
    });
  }

  render() {
    const { visibility, onClose } = this.props;

    const { name, isSubmit, error } = this.state;

    return (
      <Modal
        visibility={visibility}
        onClose={onClose}
        styleName="sModal.modal__workspace sModal.modal__overflowVisible"
      >
        <div styleName="sModal.modal__header">
          <div styleName="sModal.modal__header__close">
            <TextButton title="Cancel" onClick={onClose} />
          </div>
          <div styleName="sModal.modal__header__title">{this.i(mes.newGroup)}</div>
        </div>
        <div styleName="sModal.modal__body">
          <div styleName="sModal.modal__section">
            <div>
              <div styleName="sModal.modal__input__label">{this.i(mes.groupNewLabel)}</div>
              <TextField
                styleName="sModal.modal__input"
                value={name}
                onChange={this.handleOnChangeName.bind(this)}
                length={255}
                autoFocus
                bordered
                error={error}
                id="name"
                visibility={isSubmit ? 'disabled' : 'visible'}
              />
            </div>

            <Divider />
          </div>
          <ModalButtons styleName="sModal.modal__buttons">
            <Button
              label={this.i(mes.btnCreateGroup)}
              size="medium"
              onClick={this.handleSubmit.bind(this)}
              visibility={isSubmit ? 'disabled' : 'visible'}
              styleName="sModal.modal__btn sModal.wide"
            />
          </ModalButtons>
        </div>
      </Modal>
    );
  }
}

CreateGroup.propTypes = {
  visibility: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  data: PropTypes.object,
  dispatch: PropTypes.func
};

CreateGroup.contextTypes = {
  intl: intlShape
};

export default injectIntl(CreateGroup);
