import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { intlShape, injectIntl } from 'react-intl';
import {
  Modal,
  ModalButtons,
  Button,
  TextField,
  Checkbox,
  Label,
  Upload,
  Icon,
  Utils
} from 'mw-style-react';
import {
  MODIFY_API_KEY,
  GET_SCOPES,
  UPLOAD_FILE,
  NOTIFY_LEVEL,
  SHOW_NOTIFY,
  accountComponents
} from 'constants';
import connect from 'react-redux/es/connect/connect';
import cn from 'classnames';
import AppUtils from '../../../../utils/utils';
import TextButton from '../../../TextButton';
import mes from './intl';
import sModal from '../../Modal.scss'; // eslint-disable-line no-unused-vars

const pushKeysToCheckbox = (scopes = []) => {
  const res = {};
  scopes.forEach(({ type, id }) => {
    res[`${type.split('.')[1]}:${id}`] = true;
  });

  return res;
};

class ModifyApiKey extends PureComponent {
  constructor(props) {
    super(props);
    const { data } = props;
    const { name, url, scopes, photo } = data;

    this.state = {
      isSubmit: false,
      name,
      webhookName: url,
      checkBoxes: pushKeysToCheckbox(scopes),
      initialCheckBoxes: pushKeysToCheckbox(scopes),
      photo,
      prevPhoto: photo,
      error: false,
      webhookError: false,
      activeScopesGroups: {}
    };

    this.getScopes();
  }

  // Ф-ция локализации
  i(mesId, values) {
    return AppUtils.getMes(this.context)(mesId, values);
  }

  getScopes() {
    const { dispatch } = this.props;

    dispatch({
      type: GET_SCOPES.REQUEST,
      payload: {
        callback: (res, data) => {
          if (res === 'success' && data && data.length) {
            const uniqueData = data.reduce((accumulator, item) => {
              const itemData = item.type;
              if (itemData) {
                accumulator[itemData] = true;
              }
              return accumulator;
            }, {});

            this.setState({ activeScopesGroups: uniqueData });
          }
        }
      }
    });
  }

  handleOnUploadImageChange(data) {
    const { dispatch } = this.props;
    const { prevPhoto } = this.state;
    if (data.value && data.value.length > 0) {
      dispatch({
        type: UPLOAD_FILE.REQUEST,
        payload: {
          files: [data.value[data.value.length - 1]],
          type: '',
          callback: ({ result, data = {}, message }) => {
            if (result === 'error' || data.result === 'error') {
              dispatch({
                type: SHOW_NOTIFY.REQUEST,
                payload: {
                  id: Utils.random().toString(),
                  type: NOTIFY_LEVEL.ERROR,
                  label: message || data.message || 'File exceeds maximum size limit (1MB)'
                }
              });
              data.value = [];
              this.setState({ photo: prevPhoto });
            } else {
              this.setState({ photo: `${location.origin}/${data.hash}` });
            }
          }
        }
      });
    }
  }

  handleSubmit() {
    const { dispatch, onClose, data } = this.props;
    const { workspaceId, id } = data || {};
    const { name, webhookName, checkBoxes, initialCheckBoxes, photo = '' } = this.state;

    const scps = [];

    Object.keys(checkBoxes).forEach(c => {
      if (checkBoxes[c] && !initialCheckBoxes[c]) {
        scps.push({ type: `scopes.${c.split(':')[0]}`, id: c.split(':')[1] });
      }
    });

    if (!name.length || !AppUtils.validateUrl(webhookName)) {
      this.setState({
        error: !name.length,
        webhookError: !AppUtils.validateUrl(webhookName)
      });
      return;
    }

    this.setState({
      isSubmit: true
    });

    dispatch({
      type: MODIFY_API_KEY.REQUEST,
      payload: {
        params: { workspaceId, apiKeyId: id },
        body: {
          name,
          url: webhookName,
          scopes: scps,
          photo: photo.length ? photo : undefined,
          status: data.status
        },
        callback: result => {
          if (result === 'error') {
            this.setState({
              isSubmit: false
            });
          } else {
            onClose();
          }
        }
      }
    });
  }

  handleOnChangeName(e) {
    const { value } = e;
    this.setState({
      name: value,
      error: false
    });
  }

  handleOnChangeWebhookName(e) {
    const { value } = e;
    this.setState({
      webhookName: value,
      webhookError: false
    });
  }

  handleOnChangeCheckbox(id) {
    this.setState(prev => ({
      ...prev,
      checkBoxes: {
        ...prev.checkBoxes,
        [id]: !prev.checkBoxes[id]
      }
    }));
  }

  cutScope(text) {
    return text.split('.')[1];
  }

  calculateScopes() {
    const { apiKeys } = this.props;

    const { scopes } = apiKeys || {};

    const collections = (scopes || []).reduce(
      (collections, data) => {
        if (accountComponents[data.type] === 'Account') {
          collections.accountScopes.push(data);
        } else if (accountComponents[data.type] === 'Simulator') {
          collections.controlScopes.push(data);
        } else if (accountComponents[data.type] === 'Corezoid') {
          collections.corezoidScopes.push(data);
        }
        return collections;
      },
      {
        accountScopes: [],
        controlScopes: [],
        corezoidScopes: []
      }
    );

    return collections;
  }

  handleToggleGroup(group) {
    this.setState(prev => ({
      ...prev,
      activeScopesGroups: {
        ...prev.activeScopesGroups,
        [group]: !prev.activeScopesGroups[group]
      }
    }));
  }

  render() {
    const { visibility, onClose, apiKeys } = this.props;

    const { scopes } = apiKeys || {};

    const {
      name,
      webhookName,
      isSubmit,
      error,
      webhookError,
      checkBoxes = {},
      initialCheckBoxes = {},
      photo,
      activeScopesGroups
    } = this.state;

    const { controlScopes, accountScopes, corezoidScopes } = this.calculateScopes();

    const varMapping = {
      'scopes.sa': accountScopes,
      'scopes.control': controlScopes,
      'scopes.corezoid': corezoidScopes
    };

    const scopesGroups = Object.keys(activeScopesGroups) || [];

    return (
      <Modal visibility={visibility} onClose={onClose} styleName="sModal.modal__workspace">
        <div styleName="sModal.modal__header">
          <div styleName="sModal.modal__header__close">
            <TextButton title="Cancel" onClick={onClose} />
          </div>
          <div styleName="sModal.modal__header__title">{this.i(mes.modifyApiKey)}</div>
        </div>
        <div styleName="sModal.modal__body" style={{ paddingTop: '20px', overflow: 'hidden' }}>
          <div style={{ overflowY: 'auto', overflowX: 'hidden', marginBottom: '20px' }}>
            <div>
              <div styleName="modal__upload">
                <div styleName="modal__upload__img">
                  {photo ? <img src={photo} alt="logo" /> : <Icon type="attach" />}
                </div>
                <div styleName="modal__upload__action">
                  <Upload
                    id="photo"
                    accept="image/*"
                    onChange={data => this.handleOnUploadImageChange(data)}
                  >
                    <Button type="secondary" size="medium" label="Choose file" />
                  </Upload>
                  <div styleName="modal__upload__helperText">Maximum file size allowed is 1МВ.</div>
                </div>
              </div>
            </div>
            <div>
              <div styleName="sModal.modal__input__label">{this.i(mes.apiKeyNameLabel1)}</div>
              <TextField
                styleName="sModal.modal__input"
                value={name}
                onChange={this.handleOnChangeName.bind(this)}
                placeholder={this.i(mes.apiKeyNamePlaceholder)}
                length={255}
                bordered
                error={error}
                id="name"
                visibility={isSubmit ? 'disabled' : 'visible'}
              />
            </div>
            <div>
              <div styleName="sModal.modal__input__label">
                {this.i(mes.apiKeyWebhookNameLabel1)}{' '}
                <span styleName="sModal.modal__input__label__description">
                  {this.i(mes.apiKeyWebhookNameLabel2)}
                </span>
              </div>
              <TextField
                styleName="sModal.modal__input"
                value={webhookName}
                onChange={this.handleOnChangeWebhookName.bind(this)}
                placeholder={this.i(mes.apiKeyWebhookNamePlaceholder)}
                length={255}
                autoFocus
                bordered
                error={webhookError}
                id="webhookName"
                visibility={isSubmit ? 'disabled' : 'visible'}
              />
            </div>
            <div>
              <div styleName="sModal.modal__input__label">
                {this.i(mes.apiKeyScopesLabel1)}{' '}
                <span styleName="sModal.modal__input__label__description">
                  {this.i(mes.apiKeyScopesLabel2)}
                </span>
              </div>
              <div styleName="sModal.modal__checkboxGroup" style={{ margin: 0 }}>
                <ul>
                  {scopesGroups &&
                    scopesGroups.map(el => (
                      <li key={el} styleName={cn('sModal.modal__checkboxGroup__category')}>
                        <div style={{ width: '100%' }}>
                          <div
                            role="button"
                            tabIndex="0"
                            onClick={() => this.handleToggleGroup(el)}
                            onKeyPress={() => this.handleToggleGroup(el)}
                            styleName="sModal.modal__checkboxGroup__title"
                          >
                            <Icon
                              styleName={cn('sModal.modal__checkboxGroup__icon', {
                                'sModal.modal__checkboxGroup__rotatedArrow': !activeScopesGroups[el]
                              })}
                              type="dropdown"
                              color="#393f48"
                              size="micro"
                            />
                            {accountComponents[el] || el}
                          </div>
                          {scopes && scopes.length ? (
                            <ul
                              styleName={cn('sModal.modal__checkboxGroup__permList', {
                                'sModal.modal__checkboxGroup__hidden': !activeScopesGroups[el]
                              })}
                            >
                              {varMapping[el] &&
                                varMapping[el].map(p => (
                                  <li key={`${this.cutScope(p.type)}:${p.id}`}>
                                    <Checkbox
                                      value={checkBoxes[`${this.cutScope(p.type)}:${p.id}`]}
                                      onChange={() =>
                                        this.handleOnChangeCheckbox(
                                          `${this.cutScope(p.type)}:${p.id}`
                                        )
                                      }
                                      visibility={
                                        initialCheckBoxes[`${this.cutScope(p.type)}:${p.id}`]
                                          ? 'disabled'
                                          : 'visible'
                                      }
                                      styleName="sModal.modal__checkboxGroup__checkbox"
                                    />
                                    <div styleName="sModal.modal__input__label__ellipsis">
                                      <Label
                                        fontWeight="semibold"
                                        value={`${this.cutScope(p.type)}:${p.id}`}
                                      />
                                      <br />
                                      <span styleName="sModal.modal__input__label__description">
                                        {p.name}
                                      </span>
                                    </div>
                                  </li>
                                ))}
                            </ul>
                          ) : null}
                        </div>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          </div>
          <ModalButtons styleName="sModal.modal__buttons">
            <Button
              label={this.i(mes.btnModifyApiKey)}
              size="medium"
              onClick={this.handleSubmit.bind(this)}
              visibility={isSubmit ? 'disabled' : 'visible'}
              styleName="sModal.modal__btn sModal.wide"
            />
          </ModalButtons>
        </div>
      </Modal>
    );
  }
}

ModifyApiKey.propTypes = {
  visibility: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  data: PropTypes.object,
  dispatch: PropTypes.func,
  apiKeys: PropTypes.object
};

ModifyApiKey.contextTypes = {
  intl: intlShape
};

const mapStateToProps = ({ apiKeys }) => ({
  apiKeys
});

export default injectIntl(connect(mapStateToProps)(ModifyApiKey));
