import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { IntlProvider } from 'react-intl';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ThemeProvider, CorezoidLightTheme } from 'mw-style-react';
import RootLayout from './layouts/RootLayout';
import { IntlProviderWrapper } from './IntlProvider';

/**
 * Главный контейнер
 */
class AppContainer extends Component {
  shouldComponentUpdate() {
    return false;
  }

  render() {
    const { store, messages, locale, history } = this.props;
    return (
      <Provider store={store}>
        <IntlProvider locale={locale} messages={messages}>
          <IntlProviderWrapper locale={locale} messages={messages}>
            <Router history={history}>
              <ThemeProvider theme={CorezoidLightTheme}>
                <RootLayout />
              </ThemeProvider>
            </Router>
          </IntlProviderWrapper>
        </IntlProvider>
      </Provider>
    );
  }
}

AppContainer.propTypes = {
  messages: PropTypes.object.isRequired,
  store: PropTypes.object.isRequired,
  locale: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired
};

export default AppContainer;
