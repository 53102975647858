/* eslint-disable max-len */
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { intlShape, injectIntl } from 'react-intl';
import { Modal, ModalButtons, ModalContent, Button, Checkbox, Label } from 'mw-style-react';
import { DELETE_WORKSPACE } from 'constants';
import cn from 'classnames';
import AppUtils from '../../../../utils/utils';
import TextButton from '../../../TextButton';
import mes from './intl';
import sModal from '../../Modal.scss'; // eslint-disable-line no-unused-vars

class DeleteWorkspace extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isSubmit: false,
      deleteWorkspace: false
    };
  }

  // Ф-ция локализации
  i(mesId, values) {
    return AppUtils.getMes(this.context)(mesId, values);
  }

  handleSubmit() {
    const { dispatch, onClose, data, match } = this.props;
    const { params = {} } = match || {};
    const { ext_id: workspaceId } = data;

    this.setState({
      isSubmit: true
    });

    dispatch({
      type: DELETE_WORKSPACE.REQUEST,
      payload: {
        params: { workspaceId },
        callback: result => {
          if (result === 'error') {
            this.setState({
              isSubmit: false
            });
          } else {
            onClose();
            setTimeout(() => {
              if (params.workspaceId) {
                location.href = '/choose/delete';
              }
            }, 800);
          }
        }
      }
    });
  }

  changeCheckbox(key) {
    this.setState(prevState => ({
      [key]: !prevState[key]
    }));
  }

  render() {
    const { visibility, onClose } = this.props;

    const { isSubmit, deleteWorkspace } = this.state;
    const {
      data: { name }
    } = this.props;
    const allChecked = !deleteWorkspace;

    return (
      <Modal visibility={visibility} onClose={onClose} styleName="sModal.modal__workspace">
        <div styleName="sModal.modal__header">
          <div styleName="sModal.modal__header__close">
            <TextButton title="Cancel" onClick={onClose} />
          </div>
          <div styleName="sModal.modal__header__title">{this.i(mes.deleteWorkspace)}</div>
        </div>
        <div styleName="sModal.modal__body">
          <ModalContent styleName="sModal.modal__content">
            <div>
              <div styleName="sModal.modal__description">
                Are you sure you want to delete workspace <strong>{name}</strong>? <br />
                This action is irreversible.
              </div>
              <div styleName="sModal.modal__checkboxGroup">
                <Checkbox
                  styleName="sModal.modal__checkbox__display__label"
                  value={this.state.deleteWorkspace}
                  onChange={() => this.changeCheckbox('deleteWorkspace')}
                >
                  <Label value="Delete" />{' '}
                  <span>
                    <strong>Workspace</strong>
                  </span>
                  {', '}
                  <span>
                    <strong>Users</strong>
                  </span>
                  {', '}
                  <span>
                    <strong>Groups</strong>
                  </span>
                  {', '}
                  <span>
                    <strong>Data</strong>
                  </span>
                  {', '}
                  <span>
                    <strong>Roles</strong>
                  </span>
                  .
                </Checkbox>
              </div>
            </div>
          </ModalContent>
          <ModalButtons styleName="sModal.modal__buttons">
            <Button
              label={this.i(mes.btnDelete)}
              size="medium"
              onClick={this.handleSubmit.bind(this)}
              styleName={cn('sModal.modal__btn', 'sModal.red', 'sModal.wide', {
                red__disabled: allChecked || isSubmit
              })}
              visibility={allChecked || isSubmit ? 'disabled' : 'visible'}
            />
          </ModalButtons>
        </div>
      </Modal>
    );
  }
}

DeleteWorkspace.propTypes = {
  visibility: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  data: PropTypes.object,
  dispatch: PropTypes.func,
  match: PropTypes.object
};

DeleteWorkspace.contextTypes = {
  intl: intlShape
};

export default injectIntl(withRouter(DeleteWorkspace));
