import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  btnRemoveUserTitle: {
    id: 'btnRemoveUserTitle',
    defaultMessage: 'Remove from workspace'
  },
  btnRemoveUser: {
    id: 'btnRemoveUser',
    defaultMessage: 'Remove'
  },
  bannerCautionRemoveUser: {
    id: 'bannerCautionRemoveUser',
    defaultMessage: 'Groups and API keys will be transferred to the owner of the workspace'
  }
});

export default { ...globalIntl, ...m };
