/* eslint-disable max-len */
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { intlShape, injectIntl } from 'react-intl';
import { Modal, ModalButtons, ModalContent, Button } from 'mw-style-react';
import { MODIFY_WORKSPACE } from 'constants';
import AppUtils from '../../../../utils/utils';
import TextButton from '../../../TextButton';
import mes from './intl';
import sModal from '../../Modal.scss'; // eslint-disable-line no-unused-vars

class BlockWorkspace extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isSubmit: false
    };
  }

  // Ф-ция локализации
  i(mesId, values) {
    return AppUtils.getMes(this.context)(mesId, values);
  }

  handleSubmit() {
    const { dispatch, onClose, data } = this.props;

    this.setState({
      isSubmit: true
    });

    dispatch({
      type: MODIFY_WORKSPACE.REQUEST,
      payload: {
        body: { name: data.name, photo: data.photo, status: 'block' },
        params: {
          workspaceId: data.ext_id
        },
        callback: result => {
          if (result === 'error') {
            this.setState({
              isSubmit: false
            });
          } else {
            onClose();
          }
        }
      }
    });
  }

  render() {
    const { visibility, onClose } = this.props;

    const { isSubmit } = this.state;
    const {
      data: { name }
    } = this.props;

    return (
      <Modal visibility={visibility} onClose={onClose} styleName="sModal.modal__workspace">
        <div styleName="sModal.modal__header">
          <div styleName="sModal.modal__header__close">
            <TextButton title="Cancel" onClick={onClose} />
          </div>
          <div styleName="sModal.modal__header__title">{this.i(mes.blockWorkspace)}</div>
        </div>
        <div styleName="sModal.modal__body">
          <ModalContent styleName="sModal.modal__content">
            <div>
              <div styleName="sModal.modal__description">
                Are you sure you want to block workspace <strong>{name}</strong>? <br />
                You’ll be able to activate it later.
              </div>
            </div>
          </ModalContent>
          <ModalButtons styleName="sModal.modal__buttons">
            <Button
              label={this.i(mes.btnBlock)}
              size="medium"
              onClick={this.handleSubmit.bind(this)}
              styleName="sModal.modal__btn sModal.wide"
              visibility={isSubmit ? 'disabled' : 'visible'}
            />
          </ModalButtons>
        </div>
      </Modal>
    );
  }
}

BlockWorkspace.propTypes = {
  visibility: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  data: PropTypes.object,
  dispatch: PropTypes.func
};

BlockWorkspace.contextTypes = {
  intl: intlShape
};

export default injectIntl(BlockWorkspace);
